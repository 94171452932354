import {OrderItemDelivery} from '../models/order-item-delivery.model';
import {Order} from '../models/order.model';
import {User} from '../models/user.model';
import {ECOMMERCE_TYPE} from '../constants/order-types';
import {OrderItem} from '../models/order-item.model';
import {Address} from '../models/address.model';
import {floorLastCent, isEmptyArray} from './utils';
import {CAROUSEL_TYPE, INTERNATIONAL} from '../constants/product-type';
import {ProductCatalog} from '../models/product-catalog.model';
import {HeroSlide} from '../interfaces/hero-slide';
import {ORDER_OUT_OF_STOCK} from '../constants/texts';
import {EXTRA_INTERNAL_ORDER_LENGTH, INTERNAL_ORDER_LENGTH} from '../constants/globals';
import {Features} from '../interfaces/features';
import {DistributionList} from '../models/distribution-list.model';

const orderOutOfStock = 'SOLD_OUT';


export function computeShipmentLabel(delivery: OrderItemDelivery): string {

    // IMS-BTI label has higher priority
    if (delivery.isIMS) {
        return delivery.IMSAttention;
    }

    let label = delivery.labelWithoutWslrPrefix;

    const contact = delivery.mainOrFirstContactName;
    if (contact) {
        label += ` - ${contact}`;
    }

    return label;
}

export function isWSLROrder(order: Order, currentUser: User) {
    if (!order || !currentUser) {
        return false;
    }
    const result = order.entity_id > 0 &&
        order.entity_id !== currentUser.entity_id;
    return result;
}

export function isPaymentMethodDefined(order: Order, currentUser: User, canEditSap = false) {
    if (!order) {
        return false;
    }

    if (!canEditSap) {
        return true;
    }

    if (isWSLROrder(order, currentUser)) {
        return true;
    }

    if (order.type === ECOMMERCE_TYPE) {
        return order.hasCostCenter() || order.hasCreditCard();
    } else {
        return order.hasCostCenter();
    }
}


export function computeAvailableAddresses(userAddresses: Address[], order: Order, orderItem: OrderItem,
                                          includeDelivery: OrderItemDelivery = null): Address[] {
    if (!order || order.isWSLR) {
        return [];
    }

    const allAddresses = [...userAddresses];
    return allAddresses;
}


export function findDetailedErrorMessage(error: any = {}, options: {hideResponseError?: boolean, customErrorText?: string} =  {}): string {
    if (options.hideResponseError) {
        return '';
    }

    if (options.customErrorText) {
        return options.customErrorText;
    }

    const errorData = error.error;
    if (errorData) {
        if (errorData.data) {
            if (typeof errorData.data === 'string') {
                return errorData.data
            }
            if (!isEmptyArray(errorData.data.messages)) {
                return errorData.data.messages.join('<br/>');
            }
            if ( !isEmptyArray(errorData.data)) {
                return errorData.data.join(' ');
            }

        } else if (errorData.message) {
            if (errorData.message.includes(orderOutOfStock)) {
                return ORDER_OUT_OF_STOCK;
            }
            return errorData.message;
        } else {
            return 'There was a problem connecting to the server. Please reload the page and try again.';
        }
    }
    return '';
}

export function computeDiscountPrice(price: number, discount: number ): number {
    if (!price) {
        return 0;
    }

    if (!discount) {
        discount = 0;
    }

    return floorLastCent(price * (1 - discount / 100));
}

export function computeProductPrice(product, quantity = 1, sku = null): number {
    if (!product) {
        return 0;
    }

    return product.getPrice(quantity, sku);
}

// export function computeDiscountProductPrice(product, quantity = 1, sku = null): number {
//     if (!product) {
//         return 0;
//     }
//     const price = computeProductPrice(product, quantity, sku);
//
//     if (!product.discount) {
//         return price;
//     }
//
//     return computeDiscountPrice(price, this.discount);
//
// }


export function computeMinQuantity(product, sku = null): number {
    if (!product) {
        return 0;
    }
    return product.getMinQuantity(sku);
}

export function computeGroupMinQuantity(product, sku = null): number {
    if (!product) {
        return 0;
    }
    return product.getGroupMinQuantity(sku);
}

export function computeMaxQuantity(product, sku = null): number {
    if (!product) {
        return 0;
    }
    return product.getMaxQuantity(sku);
}

export function computeAggregatedQuantity(product, sku = null): number {
    if (!product) {
        return 0;
    }
    return product.getAggregatedQuantity(sku);
}

export function computeGroupAggregatedQuantity(product, sku = null): number {
    if (!product) {
        return 0;
    }
    return product.getGroupAggregatedQuantity(sku);
}



export function convertCatalogsToHeroSlides(catalogs: ProductCatalog[]): HeroSlide[] {
    const result: HeroSlide[] = [];
    if (isEmptyArray(catalogs)) {
        return result;
    }
    catalogs.forEach( catalog => {
        if (catalog.featureType === CAROUSEL_TYPE) {
            const img = catalog.getHeroImage();
            if (img) {
                result.push({
                    image: img['url'],
                    headline: catalog.label,
                    description: '',
                    url: catalog.programDetailsUrl,
                });
            }
        }

    });
    return result.slice(0, 5);
}


// export function getSortedAddressesByWslr(addresses: Address[], userEntityId: number): WslrAddress[] {
//     if (isEmptyArray(addresses)) {
//         return [];
//     }
//
//     const findDefaultForWslr = (wslr: number, addressList: Address[]): Address[] => {
//         return findAddressByWslr(wslr, addressList).filter( a => a.is_default);
//     }
//
//     const findNonDefaultForWslr = (wslr: number, addressList: Address[]): Address[] => {
//         return findAddressByWslr(wslr, addressList).filter( a => !a.is_default);
//     }
//
//     const findAddressByWslr = (wslr: number, addressList: Address[]): Address[] => {
//         if (isEmptyArray(addressList)) {
//             return [];
//         }
//         return addressList.filter( a => (!a.entity_id || a.entity_id === wslr));
//     }
//
//     const wslrIds =  getSortedWslrIds(addresses, userEntityId);
//     const sortedAddresses: WslrAddress[] = [];
//
//     wslrIds.forEach( id => {
//         const defaultAddresses = findDefaultForWslr(id, addresses);
//         const nonDefaultAddresses = findNonDefaultForWslr(id, addresses);
//         sortedAddresses.push( {wslrId: id, default: defaultAddresses, addresses: nonDefaultAddresses });
//     })
//
//     return sortedAddresses;
// }

// export function flatternSortedAddressesByWslr(addressList: Address[], userEntityId: number) {
//     const sortedAddresses = getSortedAddressesByWslr(addressList, userEntityId);
//     // find all default;
//     const defaults = [];
//     const nonDefaults = [];
//
//     sortedAddresses.forEach( wslrAddress => {
//         if (!isEmptyArray(wslrAddress.default)) {
//             defaults.push(...wslrAddress.default);
//         }
//
//         if (!isEmptyArray(wslrAddress.addresses)) {
//             nonDefaults.push(...wslrAddress.addresses);
//         }
//     })
//
//     return [...defaults, ...nonDefaults];
// }

export function recalculateIsFirstItemInSortedAddressArray(addressList: Address[]): void {
    let currentAddressType: number = null;

    for (let i = 0; i < addressList.length; i++) {
        const currentAddress = addressList[i];
        if (!currentAddressType || currentAddressType !== currentAddress.address_type) {
            currentAddressType = currentAddress.address_type;
            addressList[i] = new Address({
                ...currentAddress,
                isWSLR: currentAddress['isWSLR'],
                isFirstItem: true,
            });
        } else {
            addressList[i] = new Address({
                ...currentAddress,
                isWSLR: currentAddress['isWSLR'],
                isFirstItem: false,
            });
        }
    }
}

export function getSortedWslrIds (addressesList: Address[], userEntityId: number): number[] {
    if (isEmptyArray(addressesList)) {
        return [];
    }
    let ids: number[] = [];

    addressesList.forEach( a => {
        const idList = a.wslrIds;

        idList.forEach( id => {
            const addressEntityId = id || userEntityId;
            if (!ids.includes(addressEntityId)) {
                ids.push(addressEntityId);
            }
        })
    });

    if (ids.length > 1) {
        if (ids.includes(userEntityId)) {
            const restIds = ids.filter(i => i !== userEntityId).sort();
            ids = [];
            ids.push(userEntityId, ...restIds);
        } else {
            ids = ids.sort();
        }
    }
    return ids;
}

// returns  error message if validation failed
export function validateWbsForUnique(order: Order, orderItem: OrderItem,  featureFlags: Features): string {
    if (isEmptyArray(orderItem.deliveries)) {
        return '';
    }

    // not with 0 qty
    const filteredDeliveries = orderItem.deliveries.filter( d => d.quantity > 0);

    if (featureFlags.showInternalOrderCodes) {
        // validate length of wbd and internal order
        for (const delivery of filteredDeliveries) {
            if (!delivery.internal_order) {
                return 'Internal Order should be provided for each shipment';
            }

            if (delivery.internalOrder.length > EXTRA_INTERNAL_ORDER_LENGTH) {
                return`Internal Order should not exceed ${EXTRA_INTERNAL_ORDER_LENGTH} symbols.`;
            }
        }

    } else if (featureFlags.showWBS) {
        const internalOrderLabel = order.isWBSDefined ? 'WBS' : 'Internal Order';
        // validate length of wbd and internal order
        for (const delivery of filteredDeliveries) {
            if (delivery.internalOrder && delivery.internalOrder !== '0') {
                if (delivery.internalOrder.length > INTERNAL_ORDER_LENGTH) {
                    return`${internalOrderLabel} should not exceed ${INTERNAL_ORDER_LENGTH} symbols.`;
                }

                if (!wbsIOSymbolsAllowed(delivery.internalOrder)) {
                    return `${internalOrderLabel} allows only digits, letters, /, #, -, . and space`;
                }
            }
        }
    }


    // check for both flags
    if (featureFlags.showInternalOrderCodes || featureFlags.showWBS) {
        const uniqueData: string[] = [];
        const  getInternalOrder = (delivery: OrderItemDelivery): string => {
            // if delivery WBS is no defined, then order WBS is used
            return  delivery.internalOrder || order.internalOrder;
        }

        for (const delivery of filteredDeliveries) {
            const uniqueKey = (delivery.uniqueAddressId ||  '') + '_' + getInternalOrder(delivery);
            if (!uniqueData.includes(uniqueKey)) {
                uniqueData.push(uniqueKey);
            } else {
                const internalOrderLabel = order.isWBSDefined ? 'WBS' : 'Internal Order';
                return `You cannot have the same address and ${internalOrderLabel} for multiple deliveries`;
            }
        }
    }


    if (!featureFlags.showInternalOrderCodes && !featureFlags.showWBS) {
        // check when no flags have been defined
        const uniqueIds: string[] =  [];
        for (const delivery of filteredDeliveries) {
            const uniqueKey: string  =  delivery.uniqueAddressId;
            if (!uniqueIds.includes(uniqueKey)) {
                uniqueIds.push(uniqueKey);
            } else {
                return `You cannot have the same address for multiple deliveries`;
            }
        }
    }

    return '';
}


// validate order item for 0 quantity and customizations, return error message or empty string
export function validateOrderItem(orderItem: OrderItem): string {
    const orderItemQuantity = orderItem.deliveriesTotalQuantity;
    // check for zero quantity
    if (!orderItem.id && orderItemQuantity === 0) {
        return `Please add a quantity before clicking ${orderItem.init_quantity > 0 ? 'Update' : 'Add to Cart'}`;
    }

    // check for customizations
    if (!orderItem.id && orderItem.product.isCustomizable) {

        if (!orderItem.isCustomizationDefined) {
            return 'Please customize the product';
        }
    }

    const minError  = validateForMinQuantity(orderItem);
    if (minError) {
        return minError;
    }

    const  addressOrShipmentMethodError  =  orderItem.validateAddressesAndShipmentMethodsError();
    if (addressOrShipmentMethodError) {
        return addressOrShipmentMethodError;
    }

    return '';
}



export function validateForMinQuantity(orderItem: OrderItem): string {
    if (!orderItem) {
        return '';
    }
    const product  =  orderItem.product;
    if (!product) {
        return '';
    }

    // only for on-demand
    if (orderItem.isBuyingWindowOrder) {
        return ''
    }

    if ( orderItem.quantity === 0) {
        return '';
    }

    if (product.hasMinQuantity && orderItem.quantity < product.minQuantity) {
        return `This item has a minimum purchase of ${product.minQuantity}`;
    }

    return '';
}

export function validateForMaxQuantity(orderItem: OrderItem): string {
    if (!orderItem) {
        return '';
    }
    const product  =  orderItem.product;
    if (!product) {
        return '';
    }

    // only for on-demand
    if (orderItem.isBuyingWindowOrder) {
        return '';
    }

    const maxQuantity  =  product.computedMaxQuantity;
    if (maxQuantity > 0) {
        if (orderItem.quantity > maxQuantity) {
            return `This item has a maximum purchase of ${maxQuantity}`;
        }
    }
    return '';
}

export function  equalsArrayCheck  (a: number[], b: number[]): boolean {
    return a?.length === b?.length &&
    a.every((v, i) => v === b[i]);
}


export function areAddressesEqual(a: Address[], b: Address[]): boolean {
    if (a.length !== b.length) {
        return false;
    }

    const uniqueIdListA  =  a.map(adr => adr.uniqueAddressId).sort();
    const uniqueIdListB  =  b.map(adr => adr.uniqueAddressId).sort();

    return uniqueIdListA.join(',') === uniqueIdListB.join(',');
}


export interface AddressUpdateResult  {address: Address,
    status: '' | 'unchanged' | 'added' | 'deleted' | 'replaced' | 'tested', previosAddress?: Address}

export function findModifiedAddress(initialAddressList: Address[], updatedAddressList: Address[]): AddressUpdateResult[] {
    if (!initialAddressList?.length) {
        if (isEmptyArray(updatedAddressList)) {
            return [];
        }
        return updatedAddressList.map(item =>  ({address : item, status: 'added'}));
    }


    if (!updatedAddressList?.length) {
        // all items are deleted
        return initialAddressList.map(item =>  ({address : item, status: 'deleted'}));
    }


    const getUniqueKey = (address: Address): string => {
        return `${address.id}_${address.address_type}`;
    }

    const findAddress =  (addressResults: AddressUpdateResult[], address: Address): AddressUpdateResult => {
        const key = getUniqueKey(address);
        return addressResults.find( i =>  getUniqueKey(i.address) === key && i.status === '' );
    }

    // clone  initial addresses
    const initialAddressListResult: AddressUpdateResult[]   = initialAddressList.map(item => ({address: item, status: ''}));

    const result: AddressUpdateResult[] = updatedAddressList.map(item =>
      ({address: item, status: '', previosAddress: null}));

    // get minimal length from 2 arrays
    const originalAddressLength = initialAddressList.length;

    // find all unchanged  items
    result.forEach(  i => {
        const origItem  =  findAddress(initialAddressListResult, i.address);
        if (origItem) {
            origItem.status = 'tested';
            i.status = 'unchanged';
        }

    });
    initialAddressListResult.forEach(  i => {
       if (!i.status) {
           // only  not checked status
           const modifiedItem  =  findAddress(result, i.address);
           if (modifiedItem) {
               modifiedItem.status = 'unchanged';
               i.status = 'tested';
           }
       }

    })

    // find replaced ot added items
    for (let i = 0; i < result.length; i++) {
        const item  =  result[i];
        if (!item.status) {
            if (i >= originalAddressLength) {
                result[i].status = 'added';
            } else {

                const initItem  = initialAddressListResult[i];
                if (!initItem.status) {
                    const initialAddress  =  initItem.address;
                    result[i].status = 'replaced';
                    result[i].previosAddress = new Address(initialAddress);
                    initItem.status = 'tested';
                    // updateResultInArray(initialAddressListResult, initialAddress, 'tested');
                } else {
                    result[i].status = 'added';
                }

            }
        }
    }


    // find deleted
    initialAddressListResult.forEach( (initialItem) => {
        if (!initialItem.status) {
            result.push({address: initialItem.address, status: 'deleted'});
            initialItem.status = 'tested';
        }
    });


    return result;
}



// subtitute  product slug  in the path with another product slug
export function replaceCurrentPathWithProductSlug(currentPath: string, productSlug: string): string {
    if (!currentPath || !productSlug) {
        return '';
    }

    const paths  = currentPath.split('/programs/');
    if (paths.length !== 2) {
        //  no programs  in the path
        return '';
    }

    const  restPaths = paths[1].split('/');
    if (restPaths.length === 0) {
        // path doesn't  contain  product slug
        return `${paths[0]}/programs/${productSlug}`;
    }

    return `${paths[0]}/programs/${restPaths[0]}/${productSlug}`;
}

export function sortDistributionLists(distributionLists: DistributionList[]): DistributionList[] {
    if (isEmptyArray(distributionLists)) {
        return [];
    }

    return distributionLists.sort((a, b) => {
        return a.label.localeCompare(b.label);
    });
}


export function sortAddresses(addressList: Address[]): Address[] {
    if (isEmptyArray(addressList)) {
        return [];
    }

    // sort by label
    const sortAddressListByLabel = (addresses: Address[]): Address[] => {
        if (isEmptyArray(addresses)) {
            return [];
        }


        const compareByLabels = (address1: Address, address2: Address): number => {
            return (address1.label?.toLowerCase() || '').localeCompare(address2.label?.toLowerCase() || '');
        }


        return addresses.sort((a, b) => {

            // Sort by active (true comes first)
            const activeDiff = Number(b.active) - Number(a.active);
            if (activeDiff !== 0) {
                return activeDiff;
            }

            // Sort by default (true comes first)
            const defaultDiff = Number(b.isDefault) - Number(a.isDefault);
            if (defaultDiff !== 0) {
                return defaultDiff;
            }

            // Sort by favorite (true comes first)
            const favoriteDiff = Number(b.isFavorite || false) - Number(a.isFavorite || false);
            if (favoriteDiff !== 0) {
                return favoriteDiff;
            }

            // Sort alphabetically by label
            return compareByLabels(a, b);

        });
    }


    // mark first item in the list
    const markFirstItem = (addresses: Address[]): Address[] => {
        if (isEmptyArray(addresses)) {
            return [];
        }
        return addresses.map( (address, index) => {
            return new Address({...address, isFirstItem: index === 0});
        })
    }


    let corporateAddresses =  sortAddressListByLabel(addressList.filter((address) => address.isCorporate));
    corporateAddresses = markFirstItem(corporateAddresses);

    let personalAddresses  =  sortAddressListByLabel(addressList.filter((address) => address.isPersonal));
    personalAddresses = markFirstItem(personalAddresses);

    // corporate  addresses  are always  first
    return  [
        ...corporateAddresses,
        ...personalAddresses,
    ];
}

// in some cases order item  creates when order doesn't  exist,
// at this moment  it's not clear whether WBS or IO is used, and it should be checked at saving api
export function checkIOCodeAgainstOrder(orderItem: OrderItem, order: Order) {
  // only for new created order items
  if (orderItem.id || isEmptyArray(orderItem.deliveries)) {
    return;
  }

  if (!order) {
    return;
  }

  if (order.wbsCode) {
    orderItem.deliveries.forEach( d => {
      if (d.internal_order) {
        d.wbs = d.internal_order;
        d.internal_order = '';
      }
    });
  } else if (order.internalOrderCode) {
    orderItem.deliveries.forEach(d => {
      if (d.wbs) {
        d.internal_order = d.wbs;
        d.wbs = '';
      }
    });
  }
}

const WBS_REGEX = /^[A-Za-z0-9\/\-\.# ]*$/;
// WBS/IO code allows only digits, letters, hyphen, dash, dot, space and slash
export function  wbsIOSymbolsAllowed(val: string): boolean {
    if (!val) {
        return true; // skip  validation  for empy
    }
    return WBS_REGEX.test(val);
}

export function getDistributionOrPresetAddresses(distrbutionList: DistributionList, presetAdresses: Address[]): Address[] {
    if (distrbutionList && distrbutionList.hasAddresses) {
        return distrbutionList.addresses;
    }

    return presetAdresses || [];
}

export function setupDistributionListDeliveries(order: Order, orderItem: OrderItem) {
    if (!order || !orderItem || !orderItem.product) {
        return;
    }

    if (isEmptyArray(order?.distributionList?.addresses)) {
        return;
    }

    if (orderItem.product.hasVariations) {
        return;
    }

    const existingDeliveries = [...orderItem.deliveries];
    const distributionListAddresses = [...order.distributionList.addresses];

    const updatedDeliveries = [];

    for (const address of distributionListAddresses) {
        const existingDelivery = existingDeliveries.find( d => d.uniqueAddressId === address.uniqueAddressId);
        if (existingDelivery) {
            // this address already exists in the deliveries
            updatedDeliveries.push(existingDelivery);
            // remove from existing deliveries
            existingDeliveries.splice(existingDeliveries.indexOf(existingDelivery), 1);
        } else {
            updatedDeliveries.push(createEmptyDeliveryForAddress(address));
        }
    }
    // add remaining deliveries if any
    if (!isEmptyArray(existingDeliveries)) {
        updatedDeliveries.push(...existingDeliveries);
    }

    orderItem.deliveries = updatedDeliveries;
}

function createEmptyDeliveryForAddress(address: Address): OrderItemDelivery  {
        const delivery = new OrderItemDelivery({
            type: 'SHIPPING',
            method: '',
            quantity: 0,
            data: {}
        });

        delivery.setAddress(address, null, {canOrder: true, isEmployee: true});
        return delivery;
}

export function formatCountdown(val: number): string {
    if (!val) {
        return '';
    }

    // get minutes and seconds
    const minutes = Math.floor(val / 60);
    const seconds = val % 60;


    const suffixSeconds = seconds > 1 ? 'seconds' : 'second';
    if (minutes === 0) {
        return `${seconds} ${suffixSeconds}`;
    }

    const suffixMinutes = minutes > 1 ? 'minutes' : 'minute';
    return  seconds !== 0 ?
        `${minutes} ${suffixMinutes} ${seconds} ${suffixSeconds}` : `${minutes} ${suffixMinutes}`;
}
export function concatAddressesForDistributionLists(list: DistributionList[]): Address[] {
    const result: Address[] = [];
    if (isEmptyArray(list)) {
        return result;
    }

    for (const dl of list) {
        if (dl.hasAddresses) {
            dl.addresses.forEach( a => {
                if (!result.find( i => i.uniqueAddressId === a.uniqueAddressId)) {
                    result.push(a);
                }
            })
        }

    }

    return result;
}
